(function () {

     // variables
     let body = document.body,
          headerHeight = document.querySelector('header').clientHeight,
          bodyRect = document.body.getBoundingClientRect(),
          elemReveal = document.querySelectorAll('.reveal'),
          lsReveal = [],
          svgRect = document.querySelector('#about svg').getBoundingClientRect(),
          offsetSvg = [true, svgRect.top - bodyRect.top - screen.height / 2];

     //functions
     let initReveal = function(){
          elemReveal.forEach(function(elem){
               let elemRect = elem.getBoundingClientRect(),
                    offset   = elemRect.top - bodyRect.top - screen.height / 2 ,
                    flag = true,
                    time = 0;
               if(elem.getAttribute("data-animation") == 'bottom'){
                    time = 800;
               } else {
                    time = 500;
               }
               elem.style.opacity = 0;
               lsReveal.push([flag,offset,elem,time]);
          });
     };

     let protectForm = function () {
          let target = document.querySelector('#j3dxjmmets');
          target.value = 'mvdd2y49t5';
     };

     let scrollTo =  function (el) {
          window.scroll({
               behavior: 'smooth',
               left: 0,
               top: el.offsetTop - 70
          });
     };

     let drawSvg = function () {
          let  n = 300,
               pathlist = document.querySelectorAll('#about svg path');
          pathlist.forEach(function(path){
               path.classList.add('draw');
          });
     };

     let loadImg = function() {
          let bgImg = new Image(),
               bg = document.querySelector('.background-bd'),
               newClass = 'background-hd';
          bgImg.src = 'src/background.jpg';
          bgImg.onload = function() {
               bg.classList.add(newClass);
               bg.classList.remove('background-bd');
          };
     };

     let cleanForm = function(form, value){
          let errors = document.querySelectorAll('.error, .error-btn, .thx');
          errors.forEach(function(error){
               error.outerHTML = "";
          });
          form.querySelectorAll('input, textarea').forEach(function(input){
               if(input.classList.contains('error-input')){
                    input.classList.remove('error-input');
               }
               if(value){
                    input.value = "";
               }
          });
     };

     let send = function(el) {
          let  formSend = el.parentElement.parentElement,
               regexMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
               regexName = /^[a-z A-Z ,.'-]+$/,
               name = document.querySelector('#name'),
               email = document.querySelector('#email'),
               message = document.querySelector('#message'),
               flag = true,
               key = document.querySelector('#j3dxjmmets');
          //clean errors
          cleanForm(formSend, false);

          //test value
          if(name.value.length < 1){
               name.insertAdjacentHTML('afterend', "<p class='error'>Vous devez renseigner votre nom</p>");
               name.classList.add('error-input');
               flag = false;
          }
          if(name.value.length > 1 && !regexName.test(name.value)){
               name.insertAdjacentHTML('afterend', "<p class='error'>Votre nom doit uniquement comporter des lettres et tirets</p>");
               name.classList.add('error-input');
               flag = false;
          }
          if(email.value.length < 1){
               email.insertAdjacentHTML('afterend', "<p class='error'>Vous devez renseigner votre adresse mail</p>");
               email.classList.add('error-input');
               flag = false;
          }
          if(email.value.length > 1 && !regexMail.test(email.value)){
               email.insertAdjacentHTML('afterend', "<p class='error'>L'adresse mail n'est pas valide</p>");
               email.classList.add('error-input');
               flag = false;
          };
          if(message.value.length < 1){
               message.insertAdjacentHTML('afterend', "<p class='error'>Vous devez renseigner votre message</p>");
               message.classList.add('error-input');
               flag = false;
          }

          if(!flag){
               document.querySelector('.wrapper-btn').insertAdjacentHTML('beforebegin', "<p class='error-btn'>Vous devez renseigner correctement tous les champs</p>");
               return;
          }
          //send
          let request = new XMLHttpRequest();
          request.open('POST', 'send.php', true);
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          request.send('&name=' + encodeURIComponent(name.value) + '&email=' + encodeURIComponent(email.value) + '&message=' + encodeURIComponent(message.value) + '&j3dxjmmets=' + encodeURIComponent(key.value));

          request.onreadystatechange = function() {
               if (request.readyState == XMLHttpRequest.DONE) {
                    cleanForm(formSend, true);
                    formSend.insertAdjacentHTML('beforebegin', "<p class='thx'>Merci ! Nous avons bien reçu votre message</p>");
                    scrollTo(document.querySelector('#contact'));
               } else {
                    cleanForm(formSend, false);
                    formSend.insertAdjacentHTML('beforebegin', "<p class='error'>Oops ! Une erreur s'est produite");
                    scrollTo(document.querySelector('#contact'));
               }
          };
     };

     let insertFont = function(value) {
          let style = document.createElement('style');
          style.innerHTML = value;
          document.head.appendChild(style);
     }

     //font local storage
     let fontLocalStorage = function(){

          let  md5 = 'd7726396a975554cbe8bfb468928f19e',
               key = 'fonts',
               cache;

          // PRE-RENDER
          try {
               cache = window.localStorage.getItem(key);
               if (cache) {
                    cache = JSON.parse(cache);
                    if (cache.md5 == md5) {
                         insertFont(cache.value);
                    } else {
                         // Busting cache when md5 doesn't match
                         window.localStorage.removeItem(key);
                         cache = null;
                    }
               }
          } catch (e) {
               // Most likely LocalStorage disabled
               return
          }

          // POST-RENDER
          if (!cache) {
               // Fonts not in LocalStorage or md5 did not match
               window.addEventListener('load', function() {
                    var request = new XMLHttpRequest(),
                         response;
                    request.open('GET', 'fonts/fonts.json', true);
                    request.onload = function() {
                         if (this.status == 200) {
                              try {
                                   response = JSON.parse(this.response);
                                   insertFont(response.value);
                                   window.localStorage.setItem(key, this.response);
                              } catch (e) {
                                   // LocalStorage is probably full
                              }
                         }
                    };
                    request.send();
               });
          }
     };

     //init
     initReveal();
     protectForm();
     loadImg();
     fontLocalStorage();

     //event
     body.addEventListener('click', function (e) {
          let el = e.target;

          if (el.classList.contains('burger')){
               document.querySelector('nav').classList.toggle('triggered');
               if(!document.querySelector('nav').classList.contains('triggered')){
                    document.querySelector('body').classList.add('no-scroll');
               } else {
                    if(document.querySelector('body').classList.contains('no-scroll')){
                         document.querySelector('body').classList.remove('no-scroll');
                    }
               }
               document.querySelector('body').classList.toggle('no-scroll');
               if(document.querySelector('.nav').getAttribute('aria-hidden') == "false"){
                    document.querySelector('.nav').setAttribute("aria-hidden", "true");
               } else {
                    document.querySelector('.nav').setAttribute("aria-hidden", "false");
               }
          };

          if (el.classList.contains('link')){
               e.preventDefault();
               if(document.querySelector('nav').classList.contains('triggered')){
                    document.querySelector('nav').classList.remove('triggered');
               }
               if(document.querySelector('body').classList.contains('no-scroll')){
                    document.querySelector('body').classList.remove('no-scroll');
               }
               scrollTo(document.querySelector('#' + el.href.split('#')[1]));
          };

          if (el.classList.contains('btn-form')){
               e.preventDefault();
               send(el);
          };
	});

     window.addEventListener('scroll', function () {
          let scrollTop = window.pageYOffset || window.scrollTop;
          if (scrollTop > offsetSvg[1] && offsetSvg[0]){
               offsetSvg[0] = false;
               drawSvg();
          }
          lsReveal.forEach(function(elem){
               if (scrollTop > elem[1] && elem[0]){
                    elem[0] = false;
                    elem[2].classList.add('anim-' + elem[2].getAttribute("data-animation"));
                    setTimeout(function(){
                         // deleteWrapper(elem);
                         elem[2].removeAttribute('style');
                    }, elem[3]);
               }
          });
     });

     window.addEventListener('resize', function(event){
          if(window.innerWidth > 850 && document.querySelector('body').classList.contains('no-scroll')){
               document.querySelector('body').classList.remove('no-scroll');
          }
     });

     // svg
     "use strict";
     if (typeof window !== "undefined" && window.addEventListener) {
          var cache = Object.create(null); // holds xhr objects to prevent multiple requests
          var checkUseElems;
          var tid; // timeout id
          var debouncedCheck = function() {
               clearTimeout(tid);
               tid = setTimeout(checkUseElems, 100);
          };
          var unobserveChanges = function() {
               return;
          };
          var observeChanges = function() {
               var observer;
               window.addEventListener("resize", debouncedCheck, false);
               window.addEventListener("orientationchange", debouncedCheck, false);
               if (window.MutationObserver) {
                    observer = new MutationObserver(debouncedCheck);
                    observer.observe(document.documentElement, {
                         childList: true,
                         subtree: true,
                         attributes: true
                    });
                    unobserveChanges = function() {
                         try {
                              observer.disconnect();
                              window.removeEventListener("resize", debouncedCheck, false);
                              window.removeEventListener("orientationchange", debouncedCheck, false);
                         } catch (ignore) {}
                    };
               } else {
                    document.documentElement.addEventListener("DOMSubtreeModified", debouncedCheck, false);
                    unobserveChanges = function() {
                         document.documentElement.removeEventListener("DOMSubtreeModified", debouncedCheck, false);
                         window.removeEventListener("resize", debouncedCheck, false);
                         window.removeEventListener("orientationchange", debouncedCheck, false);
                    };
               }
          };
          var createRequest = function(url) {
               // In IE 9, cross origin requests can only be sent using XDomainRequest.
               // XDomainRequest would fail if CORS headers are not set.
               // Therefore, XDomainRequest should only be used with cross origin requests.
               function getOrigin(loc) {
                    var a;
                    if (loc.protocol !== undefined) {
                         a = loc;
                    } else {
                         a = document.createElement("a");
                         a.href = loc;
                    }
                    return a.protocol.replace(/:/g, "") + a.host;
               }
               var Request;
               var origin;
               var origin2;
               if (window.XMLHttpRequest) {
                    Request = new XMLHttpRequest();
                    origin = getOrigin(location);
                    origin2 = getOrigin(url);
                    if (Request.withCredentials === undefined && origin2 !== "" && origin2 !== origin) {
                         Request = XDomainRequest || undefined;
                    } else {
                         Request = XMLHttpRequest;
                    }
               }
               return Request;
          };
          var xlinkNS = "http://www.w3.org/1999/xlink";
          checkUseElems = function() {
               var base;
               var bcr;
               var fallback = ""; // optional fallback URL in case no base path to SVG file was given and no symbol definition was found.
               var hash;
               var href;
               var i;
               var inProgressCount = 0;
               var isHidden;
               var isXlink = false;
               var Request;
               var url;
               var uses;
               var xhr;

               function observeIfDone() {
                    // If done with making changes, start watching for chagnes in DOM again
                    inProgressCount -= 1;
                    if (inProgressCount === 0) { // if all xhrs were resolved
                         unobserveChanges(); // make sure to remove old handlers
                         observeChanges(); // watch for changes to DOM
                    }
               }

               function attrUpdateFunc(spec) {
                    return function() {
                         if (cache[spec.base] !== true) {
                              if (spec.isXlink) {
                                   spec.useEl.setAttributeNS(xlinkNS, "xlink:href", "#" + spec.hash);
                              } else {
                                   spec.useEl.setAttribute("href", "#" + spec.hash);
                              }
                         }
                    };
               }

               function onloadFunc(xhr) {
                    return function() {
                         var body = document.body;
                         var x = document.createElement("x");
                         var svg;
                         xhr.onload = null;
                         x.innerHTML = xhr.responseText;
                         svg = x.getElementsByTagName("svg")[0];
                         if (svg) {
                              svg.setAttribute("aria-hidden", "true");
                              svg.style.position = "absolute";
                              svg.style.width = 0;
                              svg.style.height = 0;
                              svg.style.overflow = "hidden";
                              body.insertBefore(svg, body.firstChild);
                         }
                         observeIfDone();
                    };
               }

               function onErrorTimeout(xhr) {
                    return function() {
                         xhr.onerror = null;
                         xhr.ontimeout = null;
                         observeIfDone();
                    };
               }
               unobserveChanges(); // stop watching for changes to DOM
               // find all use elements
               uses = document.getElementsByTagName("use");
               for (i = 0; i < uses.length; i += 1) {
                    try {
                         bcr = uses[i].getBoundingClientRect();
                    } catch (ignore) {
                         // failed to get bounding rectangle of the use element
                         bcr = false;
                    }
                    href = uses[i].getAttribute("href");
                    if (!href) {
                         href = uses[i].getAttributeNS(xlinkNS, "href");
                         isXlink = true;
                    } else {
                         isXlink = false;
                    }
                    if (href && href.split) {
                         url = href.split("#");
                    } else {
                         url = ["", ""];
                    }
                    base = url[0];
                    hash = url[1];
                    isHidden = bcr && bcr.left === 0 && bcr.right === 0 && bcr.top === 0 && bcr.bottom === 0;
                    if (bcr && bcr.width === 0 && bcr.height === 0 && !isHidden) {
                         // the use element is empty
                         // if there is a reference to an external SVG, try to fetch it
                         // use the optional fallback URL if there is no reference to an external SVG
                         if (fallback && !base.length && hash && !document.getElementById(hash)) {
                              base = fallback;
                         }
                         if (base.length) {
                              // schedule updating xlink:href
                              xhr = cache[base];
                              if (xhr !== true) {
                                   // true signifies that prepending the SVG was not required
                                   setTimeout(attrUpdateFunc({
                                        useEl: uses[i],
                                        base: base,
                                        hash: hash,
                                        isXlink: isXlink
                                   }), 0);
                              }
                              if (xhr === undefined) {
                                   Request = createRequest(base);
                                   if (Request !== undefined) {
                                        xhr = new Request();
                                        cache[base] = xhr;
                                        xhr.onload = onloadFunc(xhr);
                                        xhr.onerror = onErrorTimeout(xhr);
                                        xhr.ontimeout = onErrorTimeout(xhr);
                                        xhr.open("GET", base);
                                        xhr.send();
                                        inProgressCount += 1;
                                   }
                              }
                         }
                    } else {
                         if (!isHidden) {
                              if (cache[base] === undefined) {
                                   // remember this URL if the use element was not empty and no request was sent
                                   cache[base] = true;
                              } else if (cache[base].onload) {
                                   // if it turns out that prepending the SVG is not necessary,
                                   // abort the in-progress xhr.
                                   cache[base].abort();
                                   delete cache[base].onload;
                                   cache[base] = true;
                              }
                         } else if (base.length && cache[base]) {
                              setTimeout(attrUpdateFunc({
                                   useEl: uses[i],
                                   base: base,
                                   hash: hash,
                                   isXlink: isXlink
                              }), 0);
                         }
                    }
               }
               uses = "";
               inProgressCount += 1;
               observeIfDone();
          };
          var winLoad;
          winLoad = function() {
               window.removeEventListener("load", winLoad, false); // to prevent memory leaks
               tid = setTimeout(checkUseElems, 0);
          };
          if (document.readyState !== "complete") {
               // The load event fires when all resources have finished loading, which allows detecting whether SVG use elements are empty.
               window.addEventListener("load", winLoad, false);
          } else {
               // No need to add a listener if the document is already loaded, initialize immediately.
               winLoad();
          }
     }

})();

function initMap() {
     let pazat = {lat: 44.706973, lng: -1.228419};
     let map = new google.maps.Map(document.getElementById('map'), {
          zoom: 14,
          center: pazat,
          mapTypeControl: false,
          styles : [
               {
                    "elementType": "geometry",
                    "stylers": [
                         {
                              "color": "#f5f5f5"
                         }
                    ]
               },
               {
                    "elementType": "labels.icon",
                    "stylers": [
                         {
                              "visibility": "off"
                         }
                    ]
               },
               {
                    "elementType": "labels.text.fill",
                    "stylers": [
                         {
                              "color": "#616161"
                         }
                    ]
               },
               {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                         {
                              "color": "#f5f5f5"
                         }
                    ]
               },
               {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                         {
                              "color": "#bdbdbd"
                         }
                    ]
               },
               {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                         {
                              "color": "#eeeeee"
                         }
                    ]
               },
               {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                         {
                              "color": "#757575"
                         }
                    ]
               },
               {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                         {
                              "color": "#e5e5e5"
                         }
                    ]
               },
               {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                         {
                              "color": "#9e9e9e"
                         }
                    ]
               },
               {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                         {
                              "color": "#ffffff"
                         }
                    ]
               },
               {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                         {
                              "color": "#757575"
                         }
                    ]
               },
               {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                         {
                              "color": "#dadada"
                         }
                    ]
               },
               {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                         {
                              "color": "#616161"
                         }
                    ]
               },
               {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                         {
                              "color": "#9e9e9e"
                         }
                    ]
               },
               {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                         {
                              "color": "#e5e5e5"
                         }
                    ]
               },
               {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                         {
                              "color": "#eeeeee"
                         }
                    ]
               },
               {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                         {
                              "color": "#93DBE7"
                         }
                    ]
               },
               {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                         {
                              "color": "#159AAD"
                         }
                    ]
               }
          ]
     });
     let marker = new google.maps.Marker({
          position: pazat,
          map: map,
          icon: 'src/pin-logo.png',
          animation: google.maps.Animation.DROP
     });
     let infowindow = new google.maps.InfoWindow({
          content: '<div class="txt-map"><h4>Pazat entreprise</h4><p>Electricité générale, diagnostic, dépannage, neuf, rénovation</p><p>2 Allée de la Chapelle</p><p>33950 Lège-Cap-Ferret</p><a href="https://goo.gl/maps/F1wrxgzeg4u" title="Voir sur Google Maps" target="_blank">Voir sur Google Maps</a></div>'
     });
     marker.addListener('click', function() {
          infowindow.open(map, marker);
     });
 }
